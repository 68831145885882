// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SignUpForm_phoneInputContainer__hRa3K {\n  font-family: inherit !important; }\n\n.SignUpForm_passInput__4WmqR {\n  position: relative; }\n\n.SignUpForm_input__bJs7u {\n  width: 100% !important;\n  margin-bottom: 0.8rem !important; }\n\n.SignUpForm_btnFlag__kvcs8 {\n  height: 85%; }\n\n.SignUpForm_passVisibleBtn__oz6gD {\n  position: absolute;\n  top: 40%;\n  right: 10px;\n  transform: translateY(-50%);\n  background: transparent;\n  border: none;\n  cursor: pointer; }\n\n.SignUpForm_submitError__ZiB6Q:last-child {\n  margin-bottom: 15px; }\n", "",{"version":3,"sources":["webpack://./src/components/pageHome/signUpPage/SignUpForm/SignUpForm.module.scss"],"names":[],"mappings":"AAAA;EACE,+BAA+B,EAAA;;AAGjC;EACE,kBAAkB,EAAA;;AAGpB;EACE,sBAAsB;EACtB,gCAAgC,EAAA;;AAGlC;EACE,WAAW,EAAA;;AAGb;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,2BAA2B;EAC3B,uBAAuB;EACvB,YAAY;EACZ,eAAe,EAAA;;AAGjB;EAEI,mBAAmB,EAAA","sourcesContent":[".phoneInputContainer {\n  font-family: inherit !important;\n}\n\n.passInput {\n  position: relative;\n}\n\n.input {\n  width: 100% !important;\n  margin-bottom: 0.8rem !important;\n}\n\n.btnFlag {\n  height: 85%;\n}\n\n.passVisibleBtn {\n  position: absolute;\n  top: 40%;\n  right: 10px;\n  transform: translateY(-50%);\n  background: transparent;\n  border: none;\n  cursor: pointer;\n}\n\n.submitError {\n  &:last-child {\n    margin-bottom: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phoneInputContainer": "SignUpForm_phoneInputContainer__hRa3K",
	"passInput": "SignUpForm_passInput__4WmqR",
	"input": "SignUpForm_input__bJs7u",
	"btnFlag": "SignUpForm_btnFlag__kvcs8",
	"passVisibleBtn": "SignUpForm_passVisibleBtn__oz6gD",
	"submitError": "SignUpForm_submitError__ZiB6Q"
};
export default ___CSS_LOADER_EXPORT___;
