import './translations/i18n'
import './scss/index.scss'
import 'drawflow/dist/drawflow.min.css'

import { ThemeProvider } from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App'
import theme from './muiConfig'
import store, { persistor } from './redux/store'

import 'moment/locale/de'

ReactDOM.render(
  <BrowserRouter>
    <PersistGate persistor={persistor}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </LocalizationProvider>
        </Provider>
      </ThemeProvider>
    </PersistGate>
  </BrowserRouter>,
  document.getElementById('root')
)
