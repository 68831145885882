import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ConfirmModalSchema } from 'redux/reducers/common/confirmModal/confirmModalTypes'

const initialState: ConfirmModalSchema = {
  confirmAction: null,
  isActive: false,
  text: ''
}

const confirmModalSlice = createSlice({
  name: 'confirmModal',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<{ confirmAction: Function, text: string }>) => {
      state.isActive = true
      state.confirmAction = action.payload.confirmAction
      state.text = action.payload.text
    },
    close: (state, action: PayloadAction<undefined>) => {
      state.isActive = false
      state.confirmAction = null
      state.text = ''
    }
  }
})

export const { actions: confirmModalActions } = confirmModalSlice
export const { reducer: confirmModalReducer } = confirmModalSlice
