import { Box, Button, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import IconEyeClosed from 'assets/images/icons/eye-closed.png'
import IconEyeOpen from 'assets/images/icons/eye-open.png'
import FormErrorMessage from 'components/common/formErrorMessage/formErrorMessage'
import cls from 'components/pageHome/loginPage/LoginForm/LoginForm.module.scss'
import AuthTitle from 'components/pageHome/ui/authTitle'
import { loginData } from 'redux/reducers/auth/types/stateTypes'
import { TState } from 'redux/store'
import { translateType } from 'translations/types'
import getAuthValidation from 'utils/valiadtion/validations'

interface IProps {
  isFetching?: boolean
  authErrors?: any

  onSubmit: (data: loginData) => Promise<void>
  recoverHandler: () => void
  t: translateType
}

const styles = {
  socialAuth: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '25px'
  },
  buttonsBlock: {
    display: 'flex',
    justifyContent: 'center',
    button: {
      margin: '0 15px'
    }
  }
}

const LoginForm: React.FC<IProps> = ({ isFetching, authErrors, recoverHandler, onSubmit, t }) => {
  const [passwordShown, setPasswordShown] = useState(false)

  const { register, handleSubmit, formState: { errors } } = useForm<loginData>()

  const validation = getAuthValidation(t)
  return (
    <div className={cls.loginForm}>
      <AuthTitle login title="common.auth.login" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <Box mb={2}>
            <TextField
              label={t('common.auth.email')} variant="standard" fullWidth
              {...register('email', { ...validation.email })} id='loginUsername' type='email'/>
            <FormErrorMessage errors={errors} name="email"/>
          </Box>
          <Box mb={2} style={{ position: 'relative' }}>
            <TextField
              label={t('common.auth.password')} variant="standard" fullWidth
              {...register('password', { ...validation.password })} id="loginPassword" type={passwordShown ? 'text' : 'password'}/>
            <button
              style={{ position: 'absolute', bottom: '0', right: '0', background: 'transparent', border: 'none', cursor: 'pointer' }}
              type="button"
              onClick={() => setPasswordShown(prevState => !prevState)}
            >
              { passwordShown
                ? <img src={IconEyeOpen} alt="Show password"/>
                : <img src={IconEyeClosed} alt="Hide password"/>
              }
            </button>
            <FormErrorMessage errors={errors} name="password"/>
          </Box>
        </>
        <Box>
          <Box mb={2} >
            <Button onClick={recoverHandler}>{t('common.auth.recoverPassword')}</Button>
          </Box>
          <Box sx={styles.buttonsBlock}>
            <Button
              size="large"
              disabled={isFetching}
              variant="contained"
              type="submit"
            >
              {t('common.auth.login')}
            </Button>
            <Link to="/signup" className="d-block">
              <Button size="large" variant="outlined">
                {t('common.auth.signUp')}
              </Button>
            </Link>
          </Box>
        </Box>
        {authErrors && <Typography className={cls.submitError} mt={2} textAlign="center" color="error">{authErrors.text}</Typography>}
      </form>
    </div>
  )
}
const mapStateToProps = (state: TState) => {
  return {
    isFetching: state.auth.isFetching,
    authErrors: state.auth.errors
  }
}
export default connect(mapStateToProps)(LoginForm)
