import { clientActions } from 'redux/reducers/client/allState/clientActions'
import {
  actionTypes,
  ISetClientActiveLoansList,
  ISetClientLoansPaymentPlanList,
  ISetApplicationsToBePaid,
  ISetActiveLoansInitFetchingStatus,
  ISetClientToastMessage,
  ISetClientLoansPaymentList, ISetClientLoansTransactionList
} from 'redux/reducers/client/allState/types/actionTypes'
import {
  clientState,
  IApplication,
  IPaymentPlan,
  IPayments, ITransaction
} from 'redux/reducers/client/allState/types/stateTypes'
import { IToastMessage } from 'types/common/types'

const initialState: clientState = {
  activeLoans: {
    initFetchingStatus: true,
    fetchingStatus: false,
    modalFetchingStatus: false,
    list: [],
    paymentPlans: [],
    payments: [],
    transactions: [],
    current: null
  },
  applicationsToBePaid: {
    list: [],
    current: null
  },
  toastMessage: null
}
const clientReducer = (state = initialState, action: actionTypes): clientState => {
  switch (action.type) {
    // <> active loans
    case clientActions.SET_ACTIVE_LOANS_INIT_FETCHING_STATUS: {
      return {
        ...state,
        activeLoans: {
          ...state.activeLoans,
          initFetchingStatus: action.payload
        }
      }
    }
    case clientActions.SET_CLIENT_ACTIVE_LOANS_LIST: {
      return {
        ...state,
        activeLoans: {
          ...state.activeLoans,
          list: action.payload
        }
      }
    }
    case clientActions.SET_CLIENT_LOANS_PAYMENT_PLAN_LIST: {
      return {
        ...state,
        activeLoans: {
          ...state.activeLoans,
          paymentPlans: action.payload
        }
      }
    }
    case clientActions.SET_CLIENT_LOANS_PAYMENT_LIST: {
      return {
        ...state,
        activeLoans: {
          ...state.activeLoans,
          payments: action.payload
        }
      }
    }
    case clientActions.SET_CLIENT_LOANS_TRANSACTION_LIST: {
      return {
        ...state,
        activeLoans: {
          ...state.activeLoans,
          transactions: action.payload
        }
      }
    }
    case clientActions.SET_CLIENT_CURRENT_ACTIVE_LOAN: {
      return {
        ...state,
        activeLoans: {
          ...state.activeLoans,
          current: action.payload
        }
      }
    }
    case clientActions.SET_ACTIVE_LOANS_MODAL_FETCHING_STATUS: {
      return {
        ...state,
        activeLoans: {
          ...state.activeLoans,
          modalFetchingStatus: action.payload
        }
      }
    }
    // </> active loans
    case clientActions.SET_APPLICATIONS_TO_BE_PAID: {
      const applications = action.payload.filter(({ status_contract }) => status_contract === 'Issued' || status_contract === 'Partially Disbursed')

      return {
        ...state,
        applicationsToBePaid: { ...state.applicationsToBePaid, list: applications }
      }
    }
    case clientActions.SET_CLIENT_TOAST_MESSAGE: {
      return {
        ...state,
        toastMessage: action.payload
      }
    }
    default:
      return state
  }
}

// <> active loans
export const setActiveLoansInitFetchungStatus = (
  status: boolean
): ISetActiveLoansInitFetchingStatus => ({
  type: clientActions.SET_ACTIVE_LOANS_INIT_FETCHING_STATUS,
  payload: status
})
export const setClientActiveLoansList = (data: IApplication[]): ISetClientActiveLoansList => ({
  type: clientActions.SET_CLIENT_ACTIVE_LOANS_LIST,
  payload: data
})
export const setClientLoansPaymentPlanList = (data: IPaymentPlan[]): ISetClientLoansPaymentPlanList => ({
  type: clientActions.SET_CLIENT_LOANS_PAYMENT_PLAN_LIST,
  payload: data
})
export const setClientLoansPaymentList = (data: IPayments[]): ISetClientLoansPaymentList => ({
  type: clientActions.SET_CLIENT_LOANS_PAYMENT_LIST,
  payload: data
})
export const setClientLoansTransactionList = (data: ITransaction[]): ISetClientLoansTransactionList => ({
  type: clientActions.SET_CLIENT_LOANS_TRANSACTION_LIST,
  payload: data
})

// </> active loans
export const setApplicationsToBePaid = (data: IApplication[]): ISetApplicationsToBePaid => ({
  type: clientActions.SET_APPLICATIONS_TO_BE_PAID,
  payload: data
})

export const setClientToastMessage = (
  toastMessage: IToastMessage | null
): ISetClientToastMessage => ({
  type: clientActions.SET_CLIENT_TOAST_MESSAGE,
  payload: toastMessage
})

export default clientReducer
