export const BASE_URL = process.env.REACT_APP_BASE_URL
export const API_URL = `${BASE_URL}/`
export const API_V2_URL = `${BASE_URL}/api-v2/`
export const CURRENCY = '$'

export const colors = {
  darkBlue: '#000015',
  menuBar: {
    // first: '#F5F778',
    // second: '#B0C8F5',
    // third: '#AEDC7C',
    // fourth: '#FB8A66',
    // fifth: '#F67CD7',
    // sixth: '#6FDECE',
    // seventh: '#5F98F2',
    // eighth: '#C3B1E1',
    // ninth: '#FAA0A0',
    hover: '#6e73ff',
    active: '#6e73ff'
  },
  createButton: {
    main: '#85CDC0',
    hover: '#4fb19f'
  }
}
