import { ThunkAction } from 'redux-thunk'

import { authApi } from 'api/authApi'
import { ISignUpData } from 'components/pageHome/signUpPage/SignUpForm/SignUpForm'
import { TState } from 'redux/store'
import { delete_cookie, getCookie } from 'utils/common/apiHelpers'

import {
  handleLoginError,
  logout,
  setAuthErrors,
  setIsFetching,
  setSettings,
  setUser
} from './authReducer'
import { authActionTypes } from './types/actionTypes'
import { loginData } from './types/stateTypes'

type authThunk = ThunkAction<void, TState, unknown, authActionTypes>

export const loginThunk =
  (data: loginData): authThunk =>
    async dispatch => {
      dispatch(setIsFetching(true))
      const formData = new FormData()
      formData.append('email', data?.email)
      formData.append('password', data?.password)
      const res = await authApi.login(formData)

      if (res.status === 200) {
        delete_cookie('token')
        document.cookie = `token=${res?.data?.token}; path=/`

        localStorage.setItem('token', res?.data?.token)

        dispatch(setUserThunk())
        dispatch(handleLoginError(null))
      } else {
        dispatch(handleLoginError({ status: res.status, text: res?.data?.error }))
      }
      dispatch(setIsFetching(false))
    }

export const setUserThunk = (): authThunk => async dispatch => {
  const token = getCookie('token')
  if (!token) return

  try {
    dispatch(
      setUser({
        email: 'test@test.com',
        id: 1,
        group: 'Client',
        role: [],
        is_social: false,
        user_flow: null,
        first_name: 'dfasd',
        last_name: 'name'
      })
    )
    // }
  } catch (error: any) {
    dispatch(logout())
    delete_cookie('token')
  }
}

export const logoutThunk = (): authThunk => async dispatch => {
  dispatch(setIsFetching(true))

  try {
    const res = await authApi.logout(getCookie('token'))

    if (res.status === 204) {
      dispatch(logout())
      delete_cookie('token')
      localStorage.clear()
    }
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(`error ${error.message} in logoutThunk`)
  } finally {
    dispatch(setIsFetching(false))
  }
}

export const signUpClientThunk =
    (data: ISignUpData): authThunk =>
      async dispatch => {
        const formData = new FormData()
        formData.append('email', data?.email)
        formData.append('phone_number', data?.phone_number)
        formData.append('password1', data?.password)
        formData.append('password2', data?.confirm_password || '')

        const res = await authApi.signUpClient(formData)
        if ((res.status === 201) || (res.status === 200)) return true
        if ((res.status === 400) || (res.status === 404)) {
          const errors = []
          for (const item in res.data) {
            errors.push(res.data[item])
          }
          dispatch(setAuthErrors(errors))
        }
        return false
      }

export const forgotPasswordThunk =
  (email: string): authThunk =>
    async dispatch => {
      const formData = new FormData()
      formData.append('email', email)

      const res = await authApi.forgotPassword(formData)
      if ((res.status === 201) || (res.status === 200)) return true
      if ((res.status === 400) || (res.status === 404)) {
        const errors = []
        for (const item in res.data) {
          errors.push(res.data[item])
        }
        dispatch(setAuthErrors(errors))
      }
      return false
    }

export const getSettingsThunk = (): authThunk => async dispatch => {
  const res = await authApi.settings.getSettings()

  if (res.status === 200) {
    dispatch(setSettings(res.data))
  }
}
