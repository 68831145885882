// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".formErrorMessage_error__58Nt5 {\n  color: #F46B6B;\n  margin: .5rem 0 !important; }\n", "",{"version":3,"sources":["webpack://./src/components/common/formErrorMessage/formErrorMessage.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,0BAA0B,EAAA","sourcesContent":[".error {\n  color: #F46B6B;\n  margin: .5rem 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "formErrorMessage_error__58Nt5"
};
export default ___CSS_LOADER_EXPORT___;
