import { combineReducers } from '@reduxjs/toolkit'
import { applyMiddleware, createStore, PreloadedState } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import { clientReducer } from 'redux/reducers/client/clientReducer'
import { commonReducer } from 'redux/reducers/common/commonReducer'

import appReducer from './reducers/app/appReducer'
import authReducer from './reducers/auth/authReducer'
import { translationReducer } from './reducers/translation/translationReducer'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['language']
}

const reducers = combineReducers({
  language: translationReducer,
  client: clientReducer,
  auth: authReducer,
  app: appReducer,
  common: commonReducer
})

export const persistedReducer = persistReducer(persistConfig, reducers)

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 })

export const setupStore = (preloadedState?: PreloadedState<TState>) =>
  createStore(persistedReducer, preloadedState, composeEnhancers(applyMiddleware(thunk)))

const store = setupStore()

export type rootReducer = typeof persistedReducer
export type TState = ReturnType<rootReducer>
export type TAppStore = ReturnType<typeof setupStore>
export const persistor = persistStore(store)
export default store

// @ts-ignore
window.store = store
