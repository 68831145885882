import React, { FC, lazy, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router'

import ConfirmationModal from 'components/common/confirmationModal/ConfirmationModal'
import { setInitializedThunk } from 'redux/reducers/app/appReducer'
import { currentUserSelector } from 'redux/reducers/auth/authSelectors'
import { setLanguage } from 'redux/reducers/translation/translationReducer'
import { languageSelector } from 'redux/reducers/translation/translationSelectors'
import { TState } from 'redux/store'

import AppPreloader from './components/common/preloaders/appPreloader'
import HomePage from './components/pageHome/homePage'

const ClientPage = lazy(() => import('./components/pageClient/clientPage'))

interface IApp {
  setInitializedThunk: () => any
  setLanguage: (lang: string) => any
  isInit: boolean
}

const App: FC<IApp> = ({ setInitializedThunk, setLanguage, isInit }) => {
  const { t } = useTranslation()

  const currentUser = useSelector(currentUserSelector)
  const currentLanguage = useSelector(languageSelector)

  useEffect(() => {
    setLanguage(currentLanguage)
    setInitializedThunk()
  }, [setLanguage, setInitializedThunk, currentLanguage])

  return (
    <>
      <React.Suspense fallback={<AppPreloader />}>
        {isInit ? (
          <>
            <Switch>
              <Route path="/client">
                <ClientPage />
              </Route>
              <Route path="/">
                <HomePage t={t} currentUser={currentUser} />
              </Route>
            </Switch>
            <ConfirmationModal />
          </>
        ) : (
          <AppPreloader />
        )}
      </React.Suspense>
    </>
  )
}

const mapStateToProps = (state: TState) => ({
  isInit: state.app.isInitialized
})

export default connect(mapStateToProps, { setLanguage, setInitializedThunk })(App)
