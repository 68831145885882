// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomePage_homePage__BcPBY {\n  position: relative;\n  height: calc(100% - 64px);\n  padding-top: 4rem;\n  padding-bottom: 4rem; }\n\n@media (max-width: 576px) {\n  .HomePage_homePage__BcPBY {\n    height: initial; } }\n", "",{"version":3,"sources":["webpack://./src/components/pageHome/HomePage.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,iBAAiB;EACjB,oBAAoB,EAAA;;AAGtB;EACE;IACE,eAAe,EAAA,EAChB","sourcesContent":[".homePage {\n  position: relative;\n  height: calc(100% - 64px);\n  padding-top: 4rem;\n  padding-bottom: 4rem;\n}\n\n@media (max-width: 576px)  {\n  .homePage {\n    height: initial;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"homePage": "HomePage_homePage__BcPBY"
};
export default ___CSS_LOADER_EXPORT___;
