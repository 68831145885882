import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  StackProps
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { setLanguage } from 'redux/reducers/translation/translationReducer'
import { ISetLanguage, lang } from 'redux/reducers/translation/translationTypes'

interface IProps {
  setLanguage: (language: lang) => ISetLanguage
}

const LanguageMenu: React.FC<StackProps & IProps> = ({ setLanguage, ...props }) => {
  const { t } = useTranslation()

  const [showGoogleTranslate, setShowGoogleTranslate] = useState(false)
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) return
    setShowGoogleTranslate(false)
    setOpen(false)
  }
  /* const handleBtnClick = (language: lang) => {
      setLanguage(language)
      handleClose()
  } */
  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
    if (event.key === 'Escape') setOpen(false)
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current && !open) anchorRef.current!.focus()
    prevOpen.current = open
  }, [open])

  return (
    <Stack direction="row" spacing={2} {...props}>
      <div>
        <Button
          ref={anchorRef}
          id="lang-btn"
          sx={{
            padding: '6px 20px',
            color: '#000000'
          }}
          aria-controls={open ? 'lang-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          {t('common.language.lang')}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role="popper"
          placement="auto"
          transition
          disablePortal
          keepMounted
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="lang-menu"
                    aria-labelledby="lang-btn"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={e => {
                        setLanguage('en')
                        handleClose(e)
                      }}
                    >
                      English
                    </MenuItem>
                    <MenuItem
                      onClick={e => {
                        setLanguage('uk')
                        handleClose(e)
                      }}
                    >
                      Українська
                    </MenuItem>
                    <MenuItem
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '15px',
                        alignItems: 'start'
                      }}
                      onClick={e => {
                        setShowGoogleTranslate(true)
                        // eslint-disable-next-line no-new
                        new google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element')
                      }}
                    >
                      GoogleTranslate

                      <Box
                        sx={{ display: (showGoogleTranslate ? 'block' : 'none') }}
                        id='google_translate_element'
                        onClick={e => e.stopPropagation()}
                      />
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  )
}

export default connect(null, { setLanguage })(LanguageMenu)
