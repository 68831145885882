import i18next from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import en from './locales/en/translation.json'
import ru from './locales/ru/translation.json'
import uk from './locales/uk/translation.json'

const resources = {
  en: {
    translation: en
  },
  ru: {
    translation: ru
  },
  uk: {
    translation: uk
  }
}
i18next
  .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    react: {
      useSuspense: false
    }
  })
