// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoginForm_socialButtons__ncYeQ {\n  display: flex;\n  margin-bottom: 20px; }\n\n.LoginForm_submitError__2AOT5 {\n  margin-bottom: 15px !important; }\n\n@media (max-width: 768px) {\n  .LoginForm_socialButtons__ncYeQ {\n    flex-direction: column;\n    align-items: center; } }\n", "",{"version":3,"sources":["webpack://./src/components/pageHome/loginPage/LoginForm/LoginForm.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB,EAAA;;AAGrB;EACE,8BAA8B,EAAA;;AAGhC;EACE;IACE,sBAAsB;IACtB,mBAAmB,EAAA,EACpB","sourcesContent":[".socialButtons {\n  display: flex;\n  margin-bottom: 20px;\n}\n\n.submitError {\n  margin-bottom: 15px !important;\n}\n\n@media (max-width: 768px)  {\n  .socialButtons {\n    flex-direction: column;\n    align-items: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"socialButtons": "LoginForm_socialButtons__ncYeQ",
	"submitError": "LoginForm_submitError__2AOT5"
};
export default ___CSS_LOADER_EXPORT___;
