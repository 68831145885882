import { Box, Button, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import { connect } from 'react-redux'

import IconEyeClosed from 'assets/images/icons/eye-closed.png'
import IconEyeOpen from 'assets/images/icons/eye-open.png'
import FormErrorMessage from 'components/common/formErrorMessage/formErrorMessage'
import { signUpClientThunk } from 'redux/reducers/auth/authThunks'
import { TState } from 'redux/store'
import { translateType } from 'translations/types'
import { getValidation } from 'utils/client/signUpValidation'

import cls from './SignUpForm.module.scss'

import 'react-phone-input-2/lib/material.css'

export interface ISignUpData {
  email: string;
  phone_number: string;
  password: string;
  confirm_password?: string;
}

interface IProps {
  formStatus: { isSubmitted: boolean; isSuccessful: boolean };
  authErrors: string[] | null;
  setFormStatus: React.Dispatch<React.SetStateAction<{ isSubmitted: boolean; isSuccessful: boolean }>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  t: translateType;
  signUpClientThunk: (data: ISignUpData) => any;
}

const SignUpForm: React.FC<IProps> = ({
  t,
  signUpClientThunk,
  setFormStatus,
  setEmail,
  formStatus,
  authErrors
}) => {
  const [passwordShown, setPasswordShown] = useState(false)

  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm<ISignUpData>()
  const validation = getValidation(t)

  const onSubmit = async (data: ISignUpData) => {
    setEmail(data.email)
    const res = await signUpClientThunk(data)
    setFormStatus({ isSubmitted: true, isSuccessful: res })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={2}>
        <TextField
          className={cls.input}
          variant="outlined"
          label={t('homePage.signUp.labelEmail')}
          {...register('email', { ...validation.email })}
          type="email"
        />
        <FormErrorMessage errors={errors} name="email"/>
      </Box>
      <Box mb={2}>
        <Controller
          control={control}
          name="phone_number"
          rules={{ ...validation.phone_number }}
          render={({ field: { ref, ...field } }) => (
            <PhoneInput
              inputProps={{ ref }}
              containerClass={cls.phoneInputContainer}
              inputClass={cls.input}
              buttonClass={cls.btnFlag}
              country={'ua'}
              specialLabel={t('homePage.signUp.labelPhone')}
              {...field}
            />
          )}
        />
        <FormErrorMessage errors={errors} name="phone_number"/>
      </Box>
      <Box mb={2} >
        <div className={cls.passInput}>
          <TextField
            className={cls.input}
            variant="outlined"
            label={t('homePage.signUp.labelPassword')}
            {...register('password', { ...validation.password })}
            type={passwordShown ? 'text' : 'password'}
            autoComplete="on"
          />
          <button
            className={cls.passVisibleBtn}
            type="button"
            tabIndex={0}
            onClick={() => setPasswordShown(prevState => !prevState)}
          >
            { passwordShown
              ? <img src={IconEyeOpen} alt="Show password"/>
              : <img src={IconEyeClosed} alt="Hide password"/>
            }
          </button>
        </div>
        <FormErrorMessage errors={errors} name="password"/>
      </Box>
      <Box mb={4}>
        <TextField
          className={cls.input}
          variant="outlined"
          label={t('homePage.signUp.labelConfirmPassword')}
          {...register('confirm_password', {
            ...validation.password,
            validate: (v) => v === watch('password') || t('formValidation.passwordConfirm')
          })}
          type={passwordShown ? 'text' : 'password'}
          autoComplete="on"
        />
        <FormErrorMessage errors={errors} name="confirm_password"/>
      </Box>
      <Button style={{ marginBottom: '45px' }} size="large" fullWidth variant="contained" type="submit">
        {t('homePage.signUp.submitBtn')}
      </Button>
      {formStatus.isSubmitted &&
        !formStatus.isSuccessful &&
        (authErrors
          ? authErrors.map((err: string, i: number) => {
            return (
              <Typography className={cls.submitError} color="error" pt={2} key={i + err}>
                { err }
              </Typography>
              // TODO: Need update error messages with i18n
            )
          })
          : null)}
    </form>
  )
}
const mapStateToProps = (state: TState) => ({
  authErrors: state.auth.authErrors
})
export default connect(mapStateToProps, { signUpClientThunk })(SignUpForm)
