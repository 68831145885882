import { Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'

import LoginForm from 'components/pageHome/loginPage/LoginForm/loginForm'
import { wavesSVG } from 'components/pageHome/ui/wavesSVG'
import { loginThunk } from 'redux/reducers/auth/authThunks'
import { loginData, userData } from 'redux/reducers/auth/types/stateTypes'
import { translateType } from 'translations/types'
import { getCookie } from 'utils/common/apiHelpers'

import cls from '../AuthPages.module.scss'

import RecoverPassword from './recoverPassword'

interface IProps {
  loginThunk: (data: loginData) => any
  t: translateType
  currentUser: userData | null
}

const LoginPage: React.FC<IProps> = ({ loginThunk, t, currentUser }) => {
  const [isRecover, setIsRecover] = useState(false)
  const history = useHistory()

  useEffect(() => {
    // switch (currentUser?.group) {
    //   case 'Client': {
    //     history.push('/client/overdue-loans')
    //     break
    //   }
    // }
    if (getCookie('token')) {
      history.push('/client/overdue-loans')
    } else {
      history.push('/login')
    }
  }, [currentUser])

  const recoverHandler = () => setIsRecover(!isRecover)

  const onSubmit = async (data: loginData) => {
    await loginThunk(data)
  }
  return (
    <Paper className={cls.paper}>
      { isRecover
        ? <RecoverPassword recoverHandler={recoverHandler} t={t}/>
        : <LoginForm onSubmit={onSubmit} t={t} recoverHandler={recoverHandler}/>
      }
      { wavesSVG(cls.waves) }
    </Paper>
  )
}

export default connect(null, { loginThunk })(LoginPage)
