import queryString from 'query-string'

import { axiosInstance, axiosInstancePrivate } from 'api/api'

export const authApi = {
  login: (data: any) => {
    return axiosInstancePrivate
      .post('client/login', data)
      .catch(err => err.response)
  },
  logout: (token: string) => {
    return axiosInstance
      .post(
        'client/logout',
        {},
        {
          headers: {
            Authorization: token
          }
        }
      )
      .catch(err => err.response)
  },
  signUpClient: (data: any) => {
    const queryParams = queryString.parse(window.location.search)

    return axiosInstancePrivate
      .post(
        'client/registration',
        data,
        { params: { ...queryParams } }
      )
      .catch(err => err.response)
  },
  forgotPassword: (data: any) => {
    const queryParams = queryString.parse(window.location.search)
    return axiosInstancePrivate
      .post(
        'client/forgot-password',
        data,
        { params: { ...queryParams } }
      )
      .catch(err => err.response)
  },
  settings: {
    getSettings: () => {
      return axiosInstancePrivate.get('settings').catch(err => err.response)
    }
  }
}
